<template>
  <nav>
    <!-- hamburger menu -->
    <a href="#" class="hamburger-menu" @click.prevent="openMenu">
      <inline-svg :src="require('../assets/hamburger.svg')"></inline-svg>
    </a>
  </nav>
</template>

<script>
export default {
  methods: {
    openMenu () {
      Event.$emit('menu-opened')
    }
  }
}
</script>
