<template>
  <section :id="id" class="bumper flex bg-lime w-full relative md:absolute overflow-hidden" :class="zIndex">

    <header>
      <headerTop v-if="isDesktop"></headerTop>
    </header>

    <div class="hide-on-load container mx-auto text-center self-center">
      <div class="flex flex-wrap h-3/4 mt-12.5vh md:mt-25vh w-full justify-center">
        <div class="heading self-center relative" :class="hoverState">
          <slot name="main"></slot>
          <div class="md:hidden">
            <slot name="menu"></slot>
          </div>
        </div>

        <div class="hidden md:block md:w-full">
          <slot name="menu"></slot>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import headerTop from '../components/header-top.vue'

export default {
  name: 'bumper-with-menu',

  components: {
    headerTop
  },

  props: [
    'id', 'zIndex', 'version'
  ],

  data () {
    return {
      isDesktop: true,
      hoverState: '',
      ver: this.version
    }
  },

  mounted () {
    this.isDesktop = (window.innerWidth > 767)
    window.addEventListener('resize', () => {
      this.isDesktop = (window.innerWidth > 767)
    })
  }
}
</script>
