<template>
  <div id="schools-page" class="page info-page w-full h-full md:overflow-hidden flex flex-wrap scroll-trigger-enabled" :class="pageState">
    <vue-headful
        title="How Schools Can Help | Homepage for Change"
        description="Grant recipients of the H4C to get BIPOC students who are interested in the arts published + paid"
        url="https://www.homepageforchange.com/schools"
    />

    <!-- SECTION: INTRO -->
    <bumperWithMenu id="hero" zIndex="z-1" version="1">
      <template v-slot:main>
        <h1 class="h3">How Schools Can Help</h1>
        <p class="w-11/12 m-auto">Homepage for Change is about supporting and uplifting BIPOC students interested in the arts. So, we need your help. We need all the schools and teachers and guidance counselors to guide these students to our program. If you see a student who is interested in the arts in your school or classroom, direct them to our site to learn about the grant program.</p>
      </template>
      <template v-slot:menu>
        <sidebarMenu location="top"></sidebarMenu>
      </template>
    </bumperWithMenu>

      <!-- SECTION: SPREAD THE WORD -->
      <!-- sidebar -->
      <section id="spread-the-word" class="hide-on-load card hidden md:flex w-full md:w-1/4 relative md:absolute overflow-hidden z-1 bg-lavender">
        <headerLeft></headerLeft>
        <div class="pin-container container">
          <div class="flex w-full">
            <sidebarMenu location="side" active="1"></sidebarMenu>
          </div>
        </div>
      </section>
      <!-- content -->
      <section class="hide-on-load card flex w-full md:w-3/4 relative md:absolute overflow-hidden z-1 bg-red">
        <headerTopRight></headerTopRight>
        <div class="pin-container container mx-auto text-center self-center">
          <div class="flex md:h-full w-full justify-center">
            <div class="copy mt-7vw">
              <h2 class="h3 m-auto">Spread The Word</h2>
              <div class="flex flex-col md:grid md:grid-cols-20">
                <div class="order-2 md:order-1 md:col-span-8 md:col-start-2">
                  <img :src="require('../assets/poster.png')" class="object-cover h-full w-full" />
                </div>
                <div class="order-1 md:col-span-10 md:col-start-11 text-center md:text-left mb-1/8 mt-4 md:mt-1/8 md:mb-0">
                  <p>We’ve designed posters to hang in your hallways, art classes, or wherever students’ eyes may linger. These posters will house all the information they need to know about Homepage for Change with directions on how to apply.</p>
                  <a :href="require('../assets/H4C-show-your-support-poster.pdf')" download="H4C-show-your-support-poster.pdf" target="_blank" class="mt-4 md:mt-8"><button class="swipe button uppercase">Download</button></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- SECTION: THE ARTIST DIRECTORY -->
      <!-- sidebar -->
      <section id="the-artist-directory" class="hide-on-load card hidden md:flex w-full md:w-1/4 relative md:absolute overflow-hidden z-1 bg-lime">
        <headerLeft></headerLeft>
        <div class="pin-container container">
          <div class="flex w-full">
            <sidebarMenu location="side" active="2"></sidebarMenu>
          </div>
        </div>
      </section>
      <!-- content -->
      <section class="hide-on-load card flex w-full md:w-3/4 relative md:absolute overflow-hidden z-1 bg-lavender">
        <headerTopRight></headerTopRight>
        <div class="pin-container container mx-auto text-center self-center">

          <div class="flex md:h-full w-full justify-center">
            <div class="mt-7vw">
              <div class="copy">
                <h2 class="h3 m-auto text-lime">The Artist Directory</h2>
                <div class="grid md:grid-cols-20">
                  <div class="md:col-span-12 md:col-start-5">
                    <p>Since the inception of Homepage for Change, we’ve been awed by the number of talented students applying each month. And while, unfortunately, we can’t award a grant to every applicant, we’ve created an artist directory where their contact information can live. This way, those who are looking to work with BIPOC artists can easily find a plethora of options in various disciplines.</p>
                    <a href="/grant-recipients" class="mt-4 md:mt-8"><button class="swipe button uppercase">Browse the<br>directory</button></a>
                  </div>
                </div>
              </div>
              <div class="flex flex-col md:flex-row md:gap-12 mt-16">
                <div class="image-with-border transform md:-translate-y-1/4">
                  <img :src="require('../assets/art-example-1.png')" />
                </div>
                <div class="image-with-border">
                  <img :src="require('../assets/art-example-2.png')" />
                </div>
                <div class="image-with-border transform md:-translate-y-1/4">
                  <img :src="require('../assets/art-example-3.png')" />
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>

      <!-- SECTION: JOIN THE MOVEMENT -->
      <!-- sidebar -->
      <section id="join-the-movement" class="hide-on-load card hidden md:flex w-full md:w-1/4 relative md:absolute overflow-hidden z-1 bg-red">
        <headerLeft></headerLeft>
        <div class="pin-container container">
          <div class="flex w-full">
            <sidebarMenu location="side" active="3"></sidebarMenu>
          </div>
        </div>
      </section>
      <!-- content -->
      <section class="hide-on-load bumper card flex w-full md:w-3/4 relative md:absolute overflow-hidden z-1 bg-lime">
        <headerTopRight></headerTopRight>
        <div class="flex pin-container container mx-auto text-center self-center">
          <div class="flex md:h-full w-full justify-center">
            <div class="heading self-center">
              <h2 class="h3 m-auto">Join The Movement</h2>
              <p class="text-plum">Help share our message and give BIPOC students interested in the arts the support they need. If there are any questions, thoughts, or general inquiries please feel free to reach out. </p>
              <p><a href="mailto:hi@homepageforchange" class="swipe font-bold mt-4 md:mt-8">hi@homepageforchange.com</a></p>
            </div>
          </div>
        </div>
      </section>
  </div>
</template>

<script>
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

import headerLeft from '../components/header-left.vue'
import headerTopRight from '../components/header-top-right.vue'
import bumperWithMenu from '../components/bumper-with-menu.vue'
import sidebarMenu from '../components/sidebar-menu-schools.vue'

// Set up Greensock
gsap.registerPlugin(ScrollTrigger)

export default {
  name: 'Index',

  tl: '',

  components: {
    bumperWithMenu, headerLeft, headerTopRight, sidebarMenu
  },

  data () {
    return {
      pageState: 'preloading'
    }
  },

  created () {
    Event.$on('menu-closed', () => { this.pageState = '' })
    Event.$on('menu-opened', () => { this.pageState = 'blur' })

    const app = this

    window.addEventListener('load', function (event) {
      app.cards = document.querySelectorAll('.card')

      app.requestId = null

      // Set up the timeline animation
      if (window.innerWidth > 767) {
        app.initTimeline()
        app.buildTimeline()
        window.addEventListener('resize', app.requestResize)
        window.tl = this.tl
      }
    })
  },

  mounted () {
    setTimeout(() => { this.pageState = '' }, 1000)
  },

  methods: {
    initTimeline () {
      const app = this
      const rootElem = document.getElementById('schools-page')
      const bumpers = document.querySelectorAll('.bumper')

      app.tl = gsap.timeline({
        // Attach it to the scroll
        scrollTrigger: {
          id: 'st',
          trigger: '#schools-page',
          pin: true,
          start: 'top top',
          end: () => `+=${rootElem.offsetHeight * (app.cards.length / 2 + bumpers.length)}`,
          scrub: 0.3,
          invalidateOnRefresh: true,
          onRefresh: self => app.tl.progress(self.progress)
        }
      })
    },

    buildTimeline (progress) {
      // Animation duration
      const d = 1

      // Move the first two cards into the center position
      this.tl.fromTo('.card:nth-child(2)', { yPercent: 100 }, { yPercent: 0, duration: d, ease: 'linear' })
      this.tl.fromTo('.card:nth-child(2) .pin-container', { yPercent: -100 }, { yPercent: 0, duration: d, ease: 'linear' }, '-=' + d)
      this.tl.fromTo('.card:nth-child(2) .nav-element', { y: -1 * innerHeight }, { y: 0, duration: d, ease: 'linear' }, '-=' + d)

      this.tl.fromTo('.card:nth-child(3)', { yPercent: -100 }, { yPercent: 0, duration: d, ease: 'linear' }, '-=' + d)
      this.tl.fromTo('.card:nth-child(3) .pin-container', { yPercent: 100 }, { yPercent: 0, duration: d, ease: 'linear' }, '-=' + d)
      this.tl.fromTo('.card:nth-child(3) .nav-element', { y: innerHeight }, { y: 0, duration: d, ease: 'linear' }, '-=' + d)

      // Hide the hero
      this.tl.to('#hero', { yPercent: 100, duration: 0, ease: 'linear' })

      // Animate all the cards
      for (let i = 1; i < this.cards.length - 2; i += 2) {
        // target                       //from  //to    //duration //delay
        this.tl.fromTo('.card:nth-child(' + (i + 1) + ')', { yPercent: 0 }, { yPercent: -100, duration: d, ease: 'linear' })
        this.tl.fromTo('.card:nth-child(' + (i + 1) + ') .pin-container', { yPercent: 0 }, { yPercent: 100, duration: d, ease: 'linear' }, '-=' + d)
        if (document.querySelector('.card:nth-child(' + (i + 1) + ') .nav-element')) {
          this.tl.fromTo('.card:nth-child(' + (i + 1) + ') .nav-element', { y: 0 }, { y: innerHeight, duration: d, ease: 'linear' }, '-=' + d)
        }

        this.tl.fromTo('.card:nth-child(' + (i + 3) + ')', { yPercent: 100 }, { yPercent: 0, duration: d, ease: 'linear' }, '-=' + d)
        this.tl.fromTo('.card:nth-child(' + (i + 3) + ') .pin-container', { yPercent: -100 }, { yPercent: 0, duration: d, ease: 'linear' }, '-=' + d)
        if (document.querySelector('.card:nth-child(' + (i + 3) + ') .nav-element')) {
          this.tl.fromTo('.card:nth-child(' + (i + 3) + ') .nav-element', { y: -1 * innerHeight }, { y: 0, duration: d, ease: 'linear' }, '-=' + d)
        }

        this.tl.fromTo('.card:nth-child(' + (i + 2) + ')', { yPercent: 0 }, { yPercent: 100, duration: d, ease: 'linear' }, '-=' + d)
        this.tl.fromTo('.card:nth-child(' + (i + 2) + ') .pin-container', { yPercent: 0 }, { yPercent: -100, duration: d, ease: 'linear' }, '-=' + d)
        if (document.querySelector('.card:nth-child(' + (i + 2) + ') .nav-element')) {
          this.tl.fromTo('.card:nth-child(' + (i + 2) + ') .nav-element', { y: 0 }, { y: -1 * innerHeight, duration: d, ease: 'linear' }, '-=' + d)
        }

        this.tl.fromTo('.card:nth-child(' + (i + 4) + ')', { yPercent: -100 }, { yPercent: 0, duration: d, ease: 'linear' }, '-=' + d)
        this.tl.fromTo('.card:nth-child(' + (i + 4) + ') .pin-container', { yPercent: 100 }, { yPercent: 0, duration: d, ease: 'linear' }, '-=' + d)
        if (document.querySelector('.card:nth-child(' + (i + 4) + ') .nav-element')) {
          this.tl.fromTo('.card:nth-child(' + (i + 4) + ') .nav-element', { y: innerHeight }, { y: 0, duration: d, ease: 'linear' }, '-=' + d)
        }
      }

      this.tl.totalProgress(progress || 0)
    },

    requestResize () {
      cancelAnimationFrame(this.requestId)
      this.requestId = requestAnimationFrame(this.resize)
    },

    resize () {
      const progress = this.tl.totalProgress()
      this.tl.seek(0).clear()
      this.buildTimeline(progress)
    }
  }
}
</script>
